import {
  AccountResponse,
  StudentResponse,
  TutorMeetingResponse,
  TutorResponse,
} from "client/openapi";
import { Account } from "types";

export function concatenateName(user: any | null): string {
  return user ? `${user.first_name} ${user.last_name}` : "";
}

/**
 * Creates a comma-separated sentence with an "and" before the final item.
 * @param items list of strings to join into a sentence
 * @returns a comma-separated sentence with an "and" before the final item
 */
export function createSentenceList(items: string[]): string {
  let list = [...items];

  switch (list.length) {
    case 0:
      return "";
    case 1:
      return list[0];
    case 2:
      return list.join(" and ");
    default:
      return list.join(", ");
  }
}
