export function generateInitials(input: string, maxLength: number = 2): string {
  let output: string = "";

  const words: string[] = input.split(" ");
  const initials: string[] = words
    .map((word) => word[0].toUpperCase())
    .slice(0, maxLength);
  output = initials.join("");

  return output;
}
