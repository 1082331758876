import { useAuth0 } from "@auth0/auth0-react";
import { TutorResponse, TutorsService } from "client/openapi";
import {
  createContext,
  Context,
  ReactNode,
  useCallback,
  useState,
  useEffect,
  useContext,
} from "react";
import { OrgRolesAndAccountContext } from "util/OrgRolesAccountContext";

interface TutorState {
  current_tutor: TutorResponse | null | undefined;
  available_tutors: TutorResponse[] | null | undefined;
  need_to_get_latest_tutor_info: boolean;
}

type CurrTutorContextInterface = TutorState & {
  update_tutor_state: (updates: Partial<TutorState>) => void;
};

const defaultTutorState: TutorState = {
  current_tutor: null,
  available_tutors: null,
  need_to_get_latest_tutor_info: false,
};

export const TutorContext = createContext<CurrTutorContextInterface>({
  ...defaultTutorState,
  update_tutor_state: () => {},
});

function getTutorByCurrentlySelectedOrg(
  tutors: TutorResponse[],
  orgId: number
): TutorResponse | undefined {
  return tutors.find((tutor) => tutor.org_id === orgId);
}

export const TutorProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuth0();
  const { currently_selected_organization } = useContext(
    OrgRolesAndAccountContext
  );

  const [available_tutors, set_available_tutors] = useState<TutorResponse[]>();
  const [current_tutor, set_current_tutor] = useState<TutorResponse>();
  const [tutorState, setTutorState] = useState<TutorState>(defaultTutorState);

  const update_tutor_state = (updates: Partial<TutorState>) => {
    setTutorState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  // fetch the current account's tutors
  const fetchTutors = useCallback(() => {
    TutorsService.getTutorsMe()
      .then((tutors) => {
        set_available_tutors(tutors);
        update_tutor_state({ need_to_get_latest_tutor_info: false });
      })
      .catch((err) => {
        console.error(`Error (#${err.code}): ${err.message}`);
      });
  }, []);

  const setCurrentTutor = useCallback(
    (tutors: TutorResponse[]) => {
      set_current_tutor(
        getTutorByCurrentlySelectedOrg(
          tutors,
          currently_selected_organization as number
        )
      );
    },
    [currently_selected_organization]
  );

  useEffect(() => {
    if (user) {
      if (!available_tutors || tutorState.need_to_get_latest_tutor_info) {
        fetchTutors();
      }

      if (
        available_tutors &&
        currently_selected_organization &&
        !tutorState.need_to_get_latest_tutor_info
      ) {
        setCurrentTutor(available_tutors);
      }

      if (
        available_tutors &&
        current_tutor &&
        !tutorState.need_to_get_latest_tutor_info
      ) {
        const newTutorState: TutorState = {
          current_tutor: current_tutor,
          available_tutors: available_tutors,
          need_to_get_latest_tutor_info: false,
        };

        setTutorState(newTutorState);
      }
    }
  }, [
    available_tutors,
    current_tutor,
    currently_selected_organization,
    fetchTutors,
    setCurrentTutor,
    tutorState.need_to_get_latest_tutor_info,
    user,
  ]);

  console.log({ tutorState }, [tutorState]);

  return (
    <TutorContext.Provider value={{ ...tutorState, update_tutor_state }}>
      {children}
    </TutorContext.Provider>
  );
};
